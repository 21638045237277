// Custom javascript for MG.


$(document).on('turbolinks:load', function() {
  // Check the page scroll position on page load
  checkScrollPosition();

  // Check the page scroll position whenever the user scrolls
  $(window).on('scroll', function() {
    checkScrollPosition();
  });

  function checkScrollPosition() {
    if ($(window).scrollTop() > 450) {
      $('#page-up-chevron').addClass('showUpChev');
    } else {
      $('#page-up-chevron').removeClass('showUpChev');
    }
  }
});



$(document).on('turbolinks:load', function() {

	// Gets the video src from the data-src on each button

	var $videoSrc;  
	$('.video-btn').click(function() {
	    $videoSrc = $(this).data( "src" );
	});
	console.log($videoSrc);

	  
	  
	// when the modal is opened autoplay it  
	$('#videoModal').on('shown.bs.modal', function (e) {
	    
	// set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
	$("#mg-video").attr('src',$videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0" ); 
	})
	  


	// stop playing the youtube video when I close the modal
	$('#videoModal').on('hide.bs.modal', function (e) {
	    // a poor man's stop video
	    $("#mg-video").attr('src',$videoSrc); 
	}) 
	    
	   
  
// document ready  
});


$(document).on('turbolinks:load', function() {

	var encEmail = "bWF0dEBtYXR0Z3ViYmEuY29t";
	const form = document.getElementById("contact");
	form.setAttribute("href", "mailto:".concat(atob(encEmail)));

});
